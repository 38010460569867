import { TmsEvent } from "integration-web-core--socle/js/assets/commons/_tms";
import { getEventTarget } from "integration-web-core--socle/js/assets/modules/_getEventTarget";
import { console, displayDebug, jsonStringToObj, testFragment, isElementVisible, getParents } from "./includes/utilities";
import build from "./build";
var ScriptState;
(function (ScriptState) {
  ScriptState[ScriptState["NotInit"] = 0] = "NotInit";
  ScriptState[ScriptState["Init"] = 1] = "Init";
  ScriptState[ScriptState["Ready"] = 2] = "Ready";
})(ScriptState || (ScriptState = {}));
const COMPONENT_ID = "tms";
const CLICK_TRACKING_DATASET_ATTRIBUTE = "data-cdl-click";
const AV_TRACKING_DATA_DATASET_ATTRIBUTE = "data-cdl-av";
const DATALAYER_FRAGMENTS_SELECTOR = "script.dataTms";
const PREPARE_FRAGMENT_TIMEOUT = 5;
const ERROR_NAME = {
  ARGUMENT: "ArgumentError",
  LOADING: "LoadingError"
};
const IMPRESSION_DATASET_ATTRIBUTE = "data-cdl-impression";
const IMPRESSION_SENT_DATASET_ATTRIBUTE = "data-cdl-impression-sent";
const IMPRESSION_TIME = 1.5;
const DATA_LAYER_DELAY_PROPERTY = "cdl_datalayer_delay";
const pageLoad = callback => {
  if (document.readyState === "complete") {
    callback();
  } else {
    window.addEventListener("load", () => {
      callback();
    });
  }
};
export default (superclass => class Tms extends superclass {
  constructor() {
    super(COMPONENT_ID);
    this.dataLayer = {};
    this.dataLayerFragments = [];
    this.dataLayerPendingFragments = [];
    this.tmsScriptState = ScriptState.NotInit;
    this.readyToBuild = false;
    this.virtualPageTrigger = true;
    this.impressionTimer = -1;
    this.debug = {
      stopAtPageView: false,
      stopAtPushEvent: false
    };
    const _this = this;
    displayDebug({
      debug: this.debug
    }, {
      stopAtPageView: () => {
        _this.debug.stopAtPageView = !_this.debug.stopAtPageView;
      },
      stopAtPushEvent: () => {
        _this.debug.stopAtPushEvent = !_this.debug.stopAtPushEvent;
      }
    });
  }
  init(queue = []) {
    const _this = this;
    this.addListeners();
    this.processQueue(queue);
    pageLoad(() => {
      this.readyToBuild = true;
      this.tryDataLayerBuild();
    });
    return {
      get dataLayer() {
        return _this.dataLayer;
      },
      dataLayerReady(...args) {
        return _this.dataLayerReady.call(_this, ...args);
      },
      prepareFragment(...args) {
        return _this.prepareFragment.call(_this, ...args);
      },
      pushFragment(...args) {
        return _this.pushFragment.call(_this, ...args);
      },
      pushEvent(...args) {
        return _this.pushEvent.call(_this, ...args);
      }
    };
  }
  processQueue(queue) {
    console.debug("⚙ Process queue", queue);
    let element = undefined;
    while (element = queue.shift()) {
      switch (element.shift()) {
        case "dataLayerReady":
          this.dataLayerReady.apply(this, element);
          break;
        case "prepareFragment":
          this.prepareFragment.apply(this, element);
          break;
        case "pushFragment":
          this.pushFragment.apply(this, element);
          break;
        case "pushEvent":
          this.pushEvent.apply(this, element);
          break;
      }
    }
  }
  dataLayerReady(callback) {
    if (this.tmsScriptState === ScriptState.Ready) {
      callback();
    } else {
      window.addEventListener(TmsEvent.DATA_LAYER_READY, callback, {
        once: true
      });
    }
  }
  addListeners() {
    this.initClickTracking();
    this.initMediaTracking();
    this.initImpressionTracking();
    this.listenForPendingFragments();
    this.listenForClientSideTCComponent();
    this.listenForRefreshTcVars();
  }
  initClickTracking() {
    document.body.addEventListener("click", e => {
      const element = getEventTarget(e.target, `[${CLICK_TRACKING_DATASET_ATTRIBUTE}], .js-tagGA, .js-cdl`);
      if (!element) return;
      if (element.dataset["tagco"] && element.dataset["tcevent"]) {
        try {
          this.pushEvent(element, element.dataset["tcevent"], jsonStringToObj(element.dataset["tagco"]));
          console.warn(`Deprecated attributes: [data-tcevent] and [data-tagco]. Use [${CLICK_TRACKING_DATASET_ATTRIBUTE}] with escaped JSON instead`, element);
        } catch (_a) {
          console.error(new SyntaxError("Failed to parse JSON fragment"));
          console.debug(element);
        }
        return;
      }
      try {
        const events = JSON.parse(element.getAttribute(CLICK_TRACKING_DATASET_ATTRIBUTE));
        events.forEach(([eventType, value]) => {
          this.pushEvent(element, eventType, value);
        });
      } catch (_b) {
        console.error(new SyntaxError("Failed to parse JSON click attribute"));
        console.debug(element);
      }
    });
  }
  initMediaTracking() {
    ["play", "playing", "pause", "ended", "seeking", "waiting"].forEach(eventName => {
      document.body.addEventListener(eventName, e => {
        const detail = getParents(e.target).reduce((acc, element) => {
          const datasetData = element.getAttribute(AV_TRACKING_DATA_DATASET_ATTRIBUTE);
          try {
            return Object.assign(acc, datasetData && JSON.parse(datasetData));
          } catch (_a) {
            console.error(new SyntaxError("Failed to parse JSON data"));
            console.debug(element);
            return acc;
          }
        }, {
          av_content_id: "NOT_DEFINED"
        });
        this.pushEvent(e.target, `cdl_media_${eventName}`, detail);
      }, true);
    });
  }
  initImpressionTracking() {
    const impressionHandler = () => {
      if (this.impressionTimer !== -1) {
        clearTimeout(this.impressionTimer);
      }
      this.impressionTimer = window.setTimeout(() => {
        document.querySelectorAll(`[${IMPRESSION_DATASET_ATTRIBUTE}]:not([${IMPRESSION_SENT_DATASET_ATTRIBUTE}])`).forEach(element => {
          if (isElementVisible(element, 0.5)) {
            try {
              const events = JSON.parse(element.getAttribute(IMPRESSION_DATASET_ATTRIBUTE) || "[]");
              events.forEach(([eventType, value]) => {
                this.pushEvent(element, eventType, value);
              });
              element.setAttribute(IMPRESSION_SENT_DATASET_ATTRIBUTE, "");
            } catch (_a) {
              console.error(new SyntaxError("Failed to parse JSON impression attribute"));
              console.debug(element);
            }
          }
        });
      }, IMPRESSION_TIME * 1000);
    };
    window.addEventListener("scroll", impressionHandler);
    window.addEventListener("resize", impressionHandler);
    const observer = new MutationObserver(impressionHandler);
    observer.observe(document, {
      subtree: true,
      attributes: true
    });
    impressionHandler();
  }
  getDOMFragments() {
    const fragments = [];
    Array.from(document.querySelectorAll(DATALAYER_FRAGMENTS_SELECTOR)).forEach(elem => {
      try {
        const fragment = JSON.parse(elem.textContent || "");
        try {
          testFragment(fragment);
          fragments.push(fragment);
        } catch (e) {
          console.error(e);
        }
      } catch (_a) {
        console.error("DOM Fragment not parsable", {
          fragment: elem.textContent
        });
      }
    });
    Array.from(document.querySelectorAll("script.dataCommandersAct, [data-tcvars], #component-storeorderpayment")).forEach(elem => {
      if (elem instanceof HTMLElement) {
        if (elem.dataset["tcvars"]) {
          fragments.push(jsonStringToObj(elem.dataset["tcvars"]));
        } else if (elem.tagName === "SCRIPT") {
          fragments.push(JSON.parse(elem.textContent || ""));
        }
        console.warn(`Deprecated selector: "script.dataCommandersAct, [data-tcvars], #component-storeorderpayment". Use "${DATALAYER_FRAGMENTS_SELECTOR}" instead`, elem);
      }
    });
    return fragments;
  }
  prepareFragment(referenceNumber) {
    const callback = fragment => {
      this.pushFragment(fragment, referenceNumber || callback);
    };
    const reference = referenceNumber || callback;
    this.dataLayerPendingFragments.push({
      reference: reference,
      timer: window.setTimeout(() => {
        console.error(new ReferenceError("Prepare fragment timeout"));
        this.pushFragment(undefined, reference);
      }, PREPARE_FRAGMENT_TIMEOUT * 1000)
    });
    console.trace("⏲ Prepare fragment", {
      reference
    });
    return callback;
  }
  pushFragment(fragment, reference = true) {
    if (reference) {
      this.virtualPageTrigger = true;
      if (reference !== true) {
        const index = this.dataLayerPendingFragments.findIndex(f => f.reference === reference);
        if (index === -1) {
          console.error(new ReferenceError("No fragment with reference"));
          return;
        }
        clearTimeout(this.dataLayerPendingFragments[index].timer);
        this.dataLayerPendingFragments.splice(index, 1);
      }
    }
    if (fragment) {
      try {
        const clonedFragment = JSON.parse(JSON.stringify(fragment));
        try {
          testFragment(clonedFragment);
          this.dataLayerFragments.push(clonedFragment);
          console.trace("🧩 Push fragment", {
            fragment: clonedFragment,
            reference
          });
        } catch (e) {
          console.error(e);
          return;
        }
      } catch (_a) {
        console.error(new TypeError("Fragment not parsable"));
        console.debug({
          fragment,
          reference
        });
        return;
      }
    } else {
      console.trace("🧩 Push empty fragment", {
        reference
      });
    }
    this.tryDataLayerBuild();
  }
  pushEvent(target, eventType, value = {}) {
    try {
      const clonedValue = JSON.parse(JSON.stringify(value));
      if (this.tmsScriptState === ScriptState.Ready) {
        console.trace("★ Push event", {
          target,
          eventType,
          value: clonedValue
        });
        if (this.debug.stopAtPushEvent) eval("debugger");
        const tmsEvent = new CustomEvent(TmsEvent.EVENT, {
          detail: {
            eventType,
            value: clonedValue
          }
        });
        try {
          target.dispatchEvent(tmsEvent);
        } catch (_a) {
          console.error(new TypeError("Target can't dispatch event"));
          console.debug({
            target
          });
        }
      } else {
        this.dataLayerReady(() => {
          window.setTimeout(() => {
            this.pushEvent(...arguments);
          }, 0);
        });
      }
    } catch (_b) {
      console.error(new TypeError("Value not parsable"));
      console.debug({
        value
      });
    }
  }
  listenForPendingFragments() {
    document.addEventListener("waitDataLayerFragment", e => {
      if (e instanceof CustomEvent) {
        console.warn('Deprecated event: "waitDataLayerFragment". Use "tms.prepareFragment()" instead', `[${e.detail.emitterId || "Undefined component"}]`);
        const {
          id
        } = e.detail;
        this.prepareFragment(id);
      } else {
        console.error(new TypeError('"waitDataLayerFragment" must be a CustomEvent type'));
      }
    });
  }
  listenForClientSideTCComponent() {
    document.addEventListener("componentTcVarsReady", e => {
      if (e instanceof CustomEvent) {
        console.warn('Deprecated event: "componentTcVarsReady". Use "tms.pushFragment(fragment)" instead', `[${e.detail.emitterId || "Undefined component"}]`);
        const {
          id,
          tcvars
        } = e.detail;
        this.pushFragment(tcvars, id);
      } else {
        console.error(new TypeError('"componentTcVarsReady" must be a CustomEvent type'));
      }
    });
  }
  listenForRefreshTcVars() {
    document.addEventListener("refreshTcVars", e => {
      console.warn('Deprecated event: "refreshTcVars". Use "tms.pushFragment()" instead', `[${e instanceof CustomEvent && e.detail.emitterId || "Undefined component"}]`);
      this.pushFragment();
    });
  }
  tryDataLayerBuild() {
    if (this.readyToBuild && this.dataLayerPendingFragments.length === 0) {
      const domFragments = this.getDOMFragments();
      console.debug("⟳ Data layer generation", {
        domFragments,
        jsFragments: this.dataLayerFragments
      });
      build([...domFragments, ...this.dataLayerFragments]).then(dataLayer => {
        Object.assign(this.dataLayer, dataLayer, this.dataLayer[DATA_LAYER_DELAY_PROPERTY] ? {} : {
          [DATA_LAYER_DELAY_PROPERTY]: performance.now() / 1000
        });
        window.dispatchEvent(new CustomEvent(TmsEvent.DATA_LAYER_CHANGE, {
          detail: JSON.parse(JSON.stringify(this.dataLayer))
        }));
        this.callTMSScripts();
      });
      this.dataLayerFragments.length = 0;
    }
  }
  callTMSScripts() {
    if (this.tmsScriptState === ScriptState.NotInit) {
      this.tmsScriptState = ScriptState.Init;
      this.virtualPageTrigger = false;
      this.loadTMSScripts();
    } else if (this.tmsScriptState === ScriptState.Ready && this.virtualPageTrigger) {
      if (this.debug.stopAtPageView) eval("debugger");
      this.virtualPageTrigger = false;
      console.info("🏁 Data layer for virtual page ready", this.dataLayer);
      const virtualPageReadyEvent = new Event(TmsEvent.VIRTUAL_PAGE_READY);
      window.dispatchEvent(virtualPageReadyEvent);
    }
  }
  loadTMSScripts() {
    function toSrc(script) {
      script.addEventListener("error", () => {
        const error = new Error(`Failed to load container [${script.src.split("/").pop()}]`);
        error.name = ERROR_NAME.LOADING;
        console.error(error);
      });
      script.src = script.dataset.src || "";
      delete script.dataset.src;
    }
    const scriptsELementListAsync = [].slice.call(document.querySelectorAll(".js-script-tms-async"));
    scriptsELementListAsync.forEach(script => {
      script.addEventListener("load", () => {
        console.debug("🗎 Container loaded", script.src.split("/").pop());
      });
      toSrc(script);
    });
    const scriptsELementList = [].slice.call(document.querySelectorAll(".js-script-tms"));
    let previousScript = undefined;
    scriptsELementList.forEach(script => {
      script.addEventListener("load", () => {
        console.debug("🗎 Container loaded", script.src.split("/").pop());
      });
      if (previousScript) {
        previousScript.addEventListener("load", () => {
          toSrc(script);
        });
      } else {
        toSrc(script);
      }
      previousScript = script;
    });
    if (previousScript) {
      previousScript.addEventListener("load", () => {
        this.updateDatalayerToReady();
      });
    } else {
      this.updateDatalayerToReady();
    }
  }
  updateDatalayerToReady() {
    if (this.debug.stopAtPageView) eval("debugger");
    this.tmsScriptState = ScriptState.Ready;
    const datalayerReadyEvent = new Event(TmsEvent.DATA_LAYER_READY);
    console.info("🏁 Data layer ready", this.dataLayer);
    window.dispatchEvent(datalayerReadyEvent);
  }
});